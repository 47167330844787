import React from "react";
import styles from './SectionGallery.module.scss';
import classnames from 'classnames';

import image1 from '../../static/image1.png'
import image2 from "../../static/image2.png";
import image3 from "../../static/image3.png";

const SectionGallery = () => {
    return (
      <section className={styles.sectionGallery}>
        <img
          className={classnames(styles.image1, styles.image)}
          src={image1}
          alt="image_gallery"
        />
        <img
          className={classnames(styles.image2, styles.image)}
          src={image2}
          alt="image_gallery"
        />
        <img
          className={classnames(styles.image3, styles.image)}
          src={image3}
          alt="image_gallery"
        />
      </section>
    );
};

export default SectionGallery;