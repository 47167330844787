import React, { useRef, useEffect, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import MapCard from "./cards/MapCard";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useSpring, animated } from "@react-spring/web";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken =
  "pk.eyJ1Ijoic3ZveWdyb3VwIiwiYSI6ImNsYmtrbXE4YjAxNzUzcHRobXRkdDlzMHIifQ.JbwBX1eYtI4udOaUQfR53g";

function Map(props) {
  const [fullScreen, setFullScreen] = useState(false);
  let { width } = useWindowDimensions();

  const mapContainer = useRef(null);
  const [springs, api] = useSpring(() => ({
    from: { x: width >= 768 ? "75%" : "0%" },
    reset: true,
    reverse: true,
  }));
  useEffect(() => {
    width <= 768 &&
      api.start({
        to: {
          x: "0%",
        },
      });
    width > 768 &&
      !fullScreen &&
      api.start({
        to: {
          x: "75%",
        },
      });
  }, [width]);
  const handleFullScreen = () => {
    fullScreen
      ? api.start({
          from: {
            x: "0",
          },
          to: {
            x: "75%",
          },
        })
      : api.start({
          from: {
            x: "75%",
          },
          to: {
            x: "0%",
          },
        });
    setFullScreen(!fullScreen);
  };
  const map = useRef(null);
  const [lng, setLng] = useState(-71.9);
  const [lat, setLat] = useState(42.35);
  let [zoom, setZoom] = useState(9);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });
  });
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });

    const geojson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            element: (
              <MapCard
                price={1400}
                rooms={7}
                bathrooms={2}
                img={require("../static/details/img-details.png")}
              />
            ),
          },
          geometry: {
            type: "Point",
            coordinates: [-71.9, 42.35],
          },
        },
        {
          type: "Feature",
          properties: {
            element: (
              <MapCard
                price={1400}
                rooms={7}
                bathrooms={2}
                img={require("../static/details/img-details.png")}
              />
            ),
          },
          geometry: {
            type: "Point",
            coordinates: [-72.7, 42.35],
          },
        },
        {
          type: "Feature",
          properties: {
            element: (
              <MapCard
                price={1000}
                rooms={2}
                bathrooms={1}
                img={require("../static/details/img-details.png")}
              />
            ),
          },
          geometry: {
            type: "Point",
            coordinates: [-70.5, 40.1],
          },
        },
        {
          type: "Feature",
          properties: {
            element: (
              <MapCard
                price={5000}
                rooms={10}
                bathrooms={3}
                img={require("../static/details/img-details.png")}
              />
            ),
          },
          geometry: {
            type: "Point",
            coordinates: [-74.5, 30.1],
          },
        },
        {
          type: "Feature",
          properties: {
            element: (
              <MapCard
                price={2000}
                rooms={4}
                bathrooms={1}
                img={require("../static/details/img-details.png")}
              />
            ),
          },
          geometry: {
            type: "Point",
            coordinates: [-74.2, 42],
          },
        },
      ],
    };

    // Add markers to the map.
    for (const marker of geojson.features) {
      // Create a React ref
      const ref = React.createRef();
      // Create a new DOM node and save it to the React ref
      ref.current = document.createElement("div");

      ref.current.innerHTML = ReactDOMServer.renderToStaticMarkup(
        marker.properties.element
      );
      // Add markers to the map.
      new mapboxgl.Marker(ref.current)
        .setLngLat(marker.geometry.coordinates)
        .addTo(map.current);
    }
  });
  return (
    <animated.div
      style={{
        ...springs,
      }}
      className="map"
    >
      <div className="sidebar">
        <div className="btn--map" onClick={handleFullScreen}>
          {fullScreen ? (
            <img src={require("../static/map/close.svg").default} alt="shrap" />
          ) : (
            <img src={require("../static/map/open.svg").default} alt="full" />
          )}
        </div>
        <div
          className="btn--map"
          onClick={() => {
            zoom++;
            map.current.flyTo({ zoom: zoom });
          }}
        >
          <img src={require("../static/map/plus.svg").default} alt="plus" />
        </div>
        <div
          className="btn--map"
          onClick={() => {
            zoom--;
            map.current.flyTo({ zoom: zoom });
          }}
        >
          <img src={require("../static/map/minus.svg").default} alt="minus" />
        </div>
      </div>
      <div ref={mapContainer} className="map-container" />
    </animated.div>
  );
}

export default Map;
