import React from "react";

function FormShort() {
  return (
    <form
      id="contact-form"
      className="contact--form"
      action="https://formsubmit.co/svoygroup.es@gmail.com"
      method="POST"
    >
      <div className="contact--inputs">
        <div>
          <label htmlFor="number">Contact number</label>
          <input placeholder="+34 00 000 00 00" type="text" name="number" />
        </div>
        <div>
          <label htmlFor="mail">Contact mail</label>
          <input placeholder="example@mail.com" type="email" name="mail" />
        </div>
        <div>
          <label htmlFor="name">Your name</label>
          <input placeholder="Devid Wilkerson" type="text" name="name" />
        </div>
      </div>
      <div className="contact--textarea">
        <label htmlFor="message">Your message</label>
        <textarea
          placeholder="Text message here"
          name="message"
          cols="30"
          rows="10"
        ></textarea>
        <button
          className="btn btn--border btn--round btn--arrow-right"
          type="submit"
        >
          Send
        </button>
      </div>
    </form>
  );
}

export default FormShort;
