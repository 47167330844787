import React from "react";
import styles from "./AboutUs.module.scss";

function AboutUs() {
  return (
    <article className="about-us--article container">
      <div className="about-us--title">
        <h2>A LITTLE </h2>
        <h2>BIT ABOUT</h2>
        <h2>US</h2>
      </div>
      <div className="about-us--description">
        <p>
          We not only carefully select property according to your individual
          needs and fully support you at all stages of registration and
          development, but also take real estate in effective management. "You
          make a choice - we take care of the rest" - perfectly fits our
          concept: All you need to do is tell us what you want and we will take
          care of the rest.
        </p>
        <p>
          We are first of all a new-format company where we try to combine the
          experience of our employees to achieve our client's goals. We have a
          truly individual approach to the needs of our customers whether they
          are interested in buying an apartment or in investing to generate
          income. Our main goal is to recommend us to our friends and relatives
          as a reliable partner and friend.
        </p>
      </div>
    </article>
  );
}

export default AboutUs;
