import React, { useState, useEffect } from "react";
import CircleButton from "../components/CircleButton";
import { NavLink } from "react-router-dom";

function BlogItemPage(props) {
  const blogItems = [
    {
      img: require("../static/img-blog.png"),
      author: "Devid Wilkerson",
      description:
        "Our architecture encourages co-existence. So does our business philosophy. We have abolished barriers of hierarchy and departmentalization to work as a single team. We believe in full transparency — and of course, that entails our clients.",
    },
    {
      img: require("../static/img-blog.png"),
      author: "Devid Wilkerson",
      description:
        "Our architecture encourages co-existence. So does our business philosophy. We have abolished barriers of hierarchy and departmentalization to work as a single team. We believe in full transparency — and of course, that entails our clients.",
    },
  ];
  const [filter, setFilter] = useState("");
  const items = [];
  const [filteredItems, setFilteredItems] = useState(items);
  useEffect(() => {
    setFilteredItems(items.filter((item) => item.category === filter));
  }, [filter]);

  const tags = ["news", "places", "licenses"];
  return (
    <div className="blog-item">
      <img src={require("../static/details/img-details.png")} alt="details" />

      <div className="blog-item--tags">
        <div className="blog-item--tags-add">
          {tags.map((tag, index) => (
            <div
              key={index}
              className={
                filter === tag
                  ? "feature-tag--tag feature-tag--tag-item "
                  : "feature-tag--tag feature--tag-blog feature-tag--tag-item btn--wave"
              }
              onClick={() => {
                setTimeout(() => {
                  setFilter(tag);
                }, 400);
              }}
            >
              <h6>{tag}</h6>
            </div>
          ))}
        </div>
        <div className="blog-item--tags-info">
          <p>22.10.2022</p>
          <p>7 minutes reading</p>
        </div>
      </div>
      <h3>What to visit in Spain</h3>
      <p>
        Our architecture encourages co-existence. So does our business
        philosophy. We have abolished barriers of hierarchy and
        departmentalization to work as a single team. We believe in full
        transparency — and of course, that entails our clients. Kategora was
        born in Bilbao in 2006 but experienced its initial growth spur in
        Hungary and Poland. As we looked to invest in emerging markets, we
        noticed these wonderful countries had low real estate prices, low
        unemployment rates, a young population, and low leverage rates when
        compared to Spain.
      </p>
      {blogItems.map((item) => (
        <div className="blog--item-component">
          <img src={item.img} alt="details" />
          <p className="blog--item-component-photo">Photo by: {item.author}</p>
          <p>{item.description}</p>
        </div>
      ))}
      <p className="blog--item-bottom">
        Our architecture encourages co-existence. So does our business
        philosophy. We have abolished barriers of hierarchy and
        departmentalization to work as a single team. We believe in full
        transparency — and of course, that entails our clients. Kategora was
        born in Bilbao in 2006 but experienced its initial growth spur in
        Hungary and Poland. As we looked to invest in emerging markets, we
        noticed these wonderful countries had low real estate prices, low
        unemployment rates, a young population, and low leverage rates when
        compared to Spain.
      </p>
      <NavLink to="/blog">
        <div className="blog-item-button ">
          <CircleButton title="Back to blog" />
        </div>
      </NavLink>
    </div>
  );
}

export default BlogItemPage;
