import React from "react";
import styles from "./ButtonLink.module.scss";

const ButtonLink = ({children, link}) => {
    return (
      <a href={link} className={styles.button}>
        {children}
      </a>
    );
};

export default ButtonLink;