import React from "react";
import CardWithNumber from "../cards/CardWithNumber";

function Offer() {
  const accommodations = [
    {
      id: 1,
      imgURL: "image1.png",
      title: "Sales of Real Estate",
      subTitle:
        "Our team will help you find a Costa del Sol  property for investment or living on any budget. ",
    },
    {
      id: 2,
      imgURL: "image2.png",
      title: "Apartments and houses for rent",
      subTitle:
        "Svoy Group is engaged in selection of housing to rent on the long term and for the holidays. ",
    },
    {
      id: 3,
      imgURL: "image3.png",
      title: "Support in preparation of documents of any complexity",
      subTitle:
        "The team of lawyers will allow you to quickly close any legal issue in real estate and not only ",
    },
    {
      id: 4,
      imgURL: "image4.png",
      title: "Real Estate for Cryptocurrency ",
      subTitle:
        "With us it is possible to buy any real estate, regardless of the budget, for cryptocurrency",
    },
    {
      id: 5,
      imgURL: "image5.png",
      title: "Property Management ",
      subTitle:
        "We do not only carefully select real estate properties based on your individual needs and fully support you at all stages of registration and development, but also take real estate in efficient management. ",
    },
    {
      id: 6,
      imgURL: "image6.png",
      title: "Concierge Service ",
      subTitle:
        "Our team provides rent-a-car service, staffing, escorting clients to legal offices, and much more.",
    },
    {
      id: 7,
      imgURL: "image7.png",
      title: "Sale of business ",
      subTitle:
        "Svoy Group successfully sells not only residential properties, but also commercial objects, and often a ready-made business of our clients.",
    },
  ];
  return (
    <div className="offer container">
      <h2 className="offer--title">WHAT WE OFFER</h2>
      <div>
        {accommodations.map((acc) => (
          <CardWithNumber
            key={acc.id}
            number={acc.id}
            imgURL={acc.imgURL}
            title={acc.title}
            subTitle={acc.subTitle}
          />
        ))}
      </div>
    </div>
  );
}

export default Offer;
