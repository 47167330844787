import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import App from "./components/App";
import "mapbox-gl/dist/mapbox-gl.css";
import "./scss/styles.scss";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBq8IDfIFE3vnJ593XhgNrz6fMIcaSFx-4",
  authDomain: "svoy-group.firebaseapp.com",
  projectId: "svoy-group",
  storageBucket: "svoy-group.appspot.com",
  messagingSenderId: "1035199069229",
  appId: "1:1035199069229:web:d43b71488436c9c8bb0ca4",
  measurementId: "G-7Z8VJN0134",
};

// // // Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
