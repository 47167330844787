import React, { useEffect } from "react";
import PlugPage from "../pages/PlugPage/PlugPage";
import { Route, Routes } from "react-router-dom/dist";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import RentItemPage from "./../pages/RentItemPage";
import ButItemPage from "./../pages/BuyItemPage";
import SearchPage from "./../pages/SearchPage";
import BlogPage from "./../pages/BlogPage";
import ContactPage from "./../pages/ContactPage";
import SellRealEstatePage from "./../pages/SellRealEstatePage";
import ServicesPage from "./../pages/ServicesPage";
import FooterLong from "./footers/FooterLong";
import Header from "./Header/Header";
import Navigation from "./Navigation/Navigation";
import SpecialOfferSection from "./RealEstateSection/SpecialOfferSection";
import HomePage from "../pages/HomePage";
import BlogItemPage from "../pages/BlogItemPage";

const App = () => {
  const location = useLocation();
  useEffect(() => {
    <Helmet>
      <meta charSet="utf-8" />
      <title>Svoy group</title>
      <meta name="title" content="REAL ESTATE AGENCY" />
      <meta name="description" content="REAL ESTATE AGENCY" />
      <link rel="icon" href="../../public/logo.png" />
    </Helmet>;
  }, []);

  return (
    <>
      {/* <Header id="pageHeader">
        <Navigation />
      </Header>
      <SpecialOfferSection /> */}
      <Routes>
        {/* <Route path="/" element={<HomePage />} /> */}
        <Route path="/" element={<PlugPage />} />
        {/* <Route path="/rent" element={<RentItemPage />} />
        <Route path="/buy" element={<ButItemPage />} />
        <Route path="/catalog" element={<SearchPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blogitem" element={<BlogItemPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/sell" element={<SellRealEstatePage />} />
        <Route path="/services" element={<ServicesPage />} /> */}
      </Routes>
      {/* {location.pathname !== "/catalog" && <FooterLong />} */}
    </>
  );
};
export default App;
