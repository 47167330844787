import React from "react";

function CardWithNumber({ number, imgURL, title, subTitle }) {
  return (
    <div className="card-with-number">
      <h2 className="card-with-number--number">0{number}</h2>
      <div className="card-with-number--img">
        <img
          src={require("../../static/plug-images/" + imgURL)}
          alt="accomodation"
        />
      </div>
      <h4 className="card-with-number--title">{title}</h4>
      <p>{subTitle}</p>
    </div>
  );
}

export default CardWithNumber;
