import React from "react";
import styles from "./PlugPage.module.scss";

// svg
import facebookIcon from "../../static/facebook.svg";
import instagramIcon from "../../static/instagram.svg";

import Header from "../../components/Header/Header";
import SectionAbout from "../../components/SectionAbout/SectionAbout";
import SectionGallery from "../../components/SectionGallery/SectionGallery";
import AboutUs from "../../components/AboutUs/AboutUs";
import Proposal from "../../components/Proposal/Proposal";
import FooterSimple from "../../components/footers/FooterSimple";
import Offer from "../../components/Offer/Offer";

const PlugPage = () => {
  return (
    <>
      <Header id="header-plugPage">
        <div className={styles.boxElement}>Real Estate Agency </div>
        <div className={styles.socialNetworks}>
          <a href="https://instagram.com/svoy_group?igshid=MDM4ZDc5MmU=">
            <img src={instagramIcon} alt="instagramIcon" />
          </a>
        </div>
      </Header>
      <main className={styles.main}>
        <SectionAbout />
        <SectionGallery />
        <AboutUs />
        <Offer />
        <Proposal />
        <FooterSimple />
      </main>
    </>
  );
};

export default PlugPage;
