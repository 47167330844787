import React from "react";
import styles from "./Header.module.scss";

import logoHeader from "../../static/logoHeader.svg";
import LogoTitle from "../../static/logoTitle.svg";
import { NavLink } from "react-router-dom";

const Header = ({ id, children }) => {
  return (
    <header id={id} className={styles.pageHeader}>
      <NavLink id="logo" to="/" className={styles.logo}>
        <img src={logoHeader} alt="logo" />
        <img src={LogoTitle} alt="logoTitle" />
      </NavLink>
      {children}
    </header>
  );
};

export default Header;
