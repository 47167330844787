import React from "react";
import FormShort from "../forms/FormShort";

function Proposal() {
  return (
    <div className="proposal container">
      <div className="proposal--text proposal--text-additional">
        <div>
          <h3>WE`LL GET BACK TO YOU ASAP</h3>
          <p>
            Please fill out this form, and a member of our team will get in
            touch as soon as possible.
          </p>
        </div>
        <div className="proposal--adds-container">
          <div className="proposal--adds">
            <p>phone</p>
            <h4>+34 62 353 24 42</h4>
            <h4>+34 62 342 26 22</h4>
          </div>
          <div className="proposal--adds">
            <p>email</p>
            <h4>estate@svoy.es</h4>
          </div>
          <div></div>
        </div>
      </div>
      <FormShort />
    </div>
  );
}

export default Proposal;
