import React from "react";

function FooterSimple() {
  return (
    <footer className="footer--simple container">
      <p>© SVOY GROUP, 2022</p>
      <a href="#header-plugPage">
        <img
          className="footer--logo"
          src={require("../../static/logo.svg").default}
          alt="svoy "
        />
      </a>
      <div>
        {/* <a href="/">
          <img
            className="footer--facebook"
            src={require("../../static/facebook.svg").default}
            alt="facebook"
          />
        </a> */}
        <a href="https://instagram.com/svoy_group?igshid=MDM4ZDc5MmU=">
          <img
            src={require("../../static/instagram.svg").default}
            alt="instagram"
          />
        </a>
      </div>
    </footer>
  );
}

export default FooterSimple;
