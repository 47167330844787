import React from "react";
import styles from "./SectionAbout.module.scss";

import ButtonLink from "../ButtonLink/ButtonLink";

const SectionAbout = () => {
  return (
    <section className={styles.sectionAbout}>
      <h4 className={styles.sectionSubtitle}>
        full website version is in progress...
      </h4>
      <h1 className={styles.sectionTitle}>
        Svoy Group is a team that will not only help you find a property,
      </h1>
      <div className={styles.wrapperAddInformation}>
        <p className={styles.sectionParagraph}>
          but also help you solve any problems that arise in the arrangement of
          life on the Costa del Sol.
        </p>
        <ButtonLink link="#contact-form">
          <div className={styles.btnContent}>contact us</div>
        </ButtonLink>
      </div>
    </section>
  );
};

export default SectionAbout;
